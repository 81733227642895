body{
  margin: 0;
  background: #ffffff;
  font-family: Helvetica;
}
hr{
  margin: 0 -16px;
  border: none;
  border-top: 1px solid #E3E3E3;
  box-shadow: none;
  margin-bottom: 16px;
}

p{
 color: #212121;
  margin: 0;
}
h1{

}
h2{

}
h3{
  margin: 0;
  margin: 16px;
}
h4{
  margin: 0;
  margin-bottom: 16px;
}

.row{
  padding: 16px;
  position: relative;
  cursor: pointer;
  background: white;
  border-bottom: 5px solid #F5F5F5;
}
.row .row-title{
  color: #212121;
  margin-bottom: 8px;
}
.row .row-text{
  color: #727272;
  margin-bottom: 4px;
}
.row .icon{
  position: absolute;
  right: 16px;
  top: calc(50% - 12px);
}

.btn{
  text-align: center;
  border-radius: 4px;
  cursor: pointer;
}
.btn.btn-large{
  padding: 12px;
}
.btn.btn-dashed{
  color: #727272;
  margin-bottom: 16px;
  border: 1px dashed #727272;
}
.btn.btn-tab {
  width: 50%;
  text-align: center;
  padding: 12px 16px;
  color: #727272;
  border-bottom: 1px solid #D8D8D8;
  cursor: pointer;
  border-radius: 0;
}

.btn.btn-tab.active{
  color: black;
  border-bottom: 2px solid black
}
.app-cont{
  max-width: 400px;
  margin: auto;
  background: white;
  min-height: 600px;
}

.subscriptions-cont{
  padding: 16px;
  list-style: none;
  margin: 0;
  padding-top: 64px;
}
.subscription-item{
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 5px 25px rgba(0, 0, 0, 0.1);
  position: relative;
  cursor: pointer;
  background: white;
}
.subscription-item .title{
  margin: 0;
  margin-bottom: 16px;
  font-size: 16px;
}

.subscription-item .variant-title{
  margin-bottom: 13px;
  font-size: 12px;
  color: #929292;
  margin-top: 5px;
}

.tabs-cont{
  display: flex;
  position: fixed;
  width: 100%;
  right: 0;
  z-index: 9999;
  background: white;
}

.contents-cont{
  display: flex;
}
.contents{

}
.product-img{
  width: 52px;
  height: 80px;
  border-radius: 4px;
  margin-right: 12px;
  background: #f4f4f4;
  object-fit: cover;
}

.subscription-item .icon{
  position: absolute;
  top: 16px;
  right: 16px;
}

/* skeleton animation */
@keyframes loader {
  from {
    background-position: -100% 0;
  }
  to {
    background-position: 100% 0;
  }
}
.row .skeleton-item .row-text {
  margin-bottom: 4px;
}
.skeleton-item{
  pointer-events: none;
  list-style: none;
}
.skeleton-item p{
  background: linear-gradient(
          90deg,
          hsl(210, 15%, 88%),
          hsl(210, 15%, 95%),
          hsl(210, 15%, 88%)
  );
  background-size: 200%;
  animation: loader 1s infinite reverse;
  border-radius: 4px !important;
  color: transparent !important;
}

